<template>
  <div>
    <b-card>
      <b-row class="match-height">
        <b-col
          xl="4"
          md="4"
        >
          <div class="flex-between mb20">
            <h4>角色列表</h4>
            <el-button
              v-if="savePerm"
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="saveDialogShow(1)"
            >
              新增角色
            </el-button>
          </div>
          <el-table
            v-loading="roleListLoading"
            :max-height="pageH"
            :row-class-name="rowCls"
            border
            :data="roleList"
            @cell-click="rowClick"
          >
            <el-table-column
              prop="roleName"
              label="角色名称"
            />
            <el-table-column
              label="操作"
              width="120px"
            >
              <template slot-scope="{row}">
                <el-link
                  v-if="updatePerm"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(2,row)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i
                      :class="row.canDelete ? '' : 'not-allow'"
                      class="el-icon-edit"
                    />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-if="rolePerm"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="toRoleAuth(row)"
                >
                  <el-tooltip
                    content="授权"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-s-check" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-if="delPerm"
                  :underline="false"
                  type="primary"
                  @click="delRole(row)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i
                      :class="row.canDelete ? '' : 'not-allow'"
                      class="el-icon-delete"
                    />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </b-col>
        <b-col
          xl="8"
          md="8"
        >
          <div class="flex-between mb20">
            <h4>用户列表</h4>
            <el-button
              v-if="saveRoleUserPerm"
              :disabled="roleActiveId == ''"
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="roleUserDialogShow"
            >
              新增用户
            </el-button>
          </div>
          <el-table
            v-loading="userListLoading"
            :max-height="pageH"
            border
            :data="userList"
          >
            <el-table-column
              width="80"
              prop="actualName"
              label="姓名"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{row}">
                {{ row.actualName || row.nickName }}
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="account"
              label="邮箱"
            />
            <el-table-column
              prop="dept_str"
              :show-overflow-tooltip="true"
              label="部门"
            />
            <el-table-column
              prop="email"
              :show-overflow-tooltip="true"
              label="邮箱"
            />
            <el-table-column
              label="操作"
              width="80px"
            >
              <template slot-scope="{row}">
                <el-link
                  v-if="delRoleUserPerm"
                  :underline="false"
                  type="primary"
                  @click="delUser(row)"
                >
                  <el-tooltip
                    content="移除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            v-show="userTotal>0"
            :total="userTotal"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.pageSize"
            @pagination="getRoleUsersList"
          />
        </b-col>
      </b-row>
      <RoleEdit ref="roleEdit" />
      <RoleSave
        ref="roleSave"
        @handleFilter="getRoleList"
      />
      <RoleUserSave
        ref="roleUserSave"
        @handleFilter="getRoleUsersList"
      />
    </b-card>
  </div>
</template>
<script>
import {
  GetRoleList, ListRoleUser, DelRoleUser, DeleteRole,
} from '@/api/system/role/role'
import pagination from '@core/components/pagination/Pagination.vue'
import RoleSave from '@/views/settings/role/RoleSave.vue'
import RoleUserSave from '@/views/settings/role/RoleUserSave.vue'
import RoleEdit from '@/views/settings/role/RoleEdit.vue'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'

export default {
  components: {
    RoleSave,
    pagination,
    RoleUserSave,
    RoleEdit,
  },
  data() {
    return {
      pageH: document.body.clientHeight - 180,
      savePerm: findBtnPerm('role-2'),
      saveRoleUserPerm: findBtnPerm('role-4'),
      updatePerm: findBtnPerm('role-3'),
      delPerm: findBtnPerm('role-6'),
      delRoleUserPerm: findBtnPerm('role-7'),
      rolePerm: findBtnPerm('role-5'),
      roleList: [],
      userList: [],
      roleActiveId: '',
      userListLoading: false,
      roleListLoading: false,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        id: '',
      },
      total: 0,
      userTotal: 0,
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'role-2')
      this.saveRoleUserPerm = verifyBtnPerm(btnPerms, 'role-4')
      this.updatePerm = verifyBtnPerm(btnPerms, 'role-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'role-6')
      this.delRoleUserPerm = verifyBtnPerm(btnPerms, 'role-7')
      this.rolePerm = verifyBtnPerm(btnPerms, 'role-5')
    })
    this.getRoleList()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    rowCls(row) {
      if (row.row.id === this.roleActiveId) {
        return 'current-row-active'
      }
    },
    delUser(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const userIdList = []
          userIdList.push(row.id)
          const formData = {
            UserIdList: userIdList,
            RoleId: this.roleActiveId,
          }
          DelRoleUser(formData)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.listQuery.page = 1
                this.getRoleUsersList()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    delRole(row) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const formData = {
            id: row.id,
          }
          DeleteRole(formData)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getRoleList()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    roleUserDialogShow() {
      this.$refs.roleUserSave.dialogVisible = true
      this.$refs.roleUserSave.form.roleId = this.roleActiveId
    },
    toRoleAuth(row) {
      this.$refs.roleEdit.dialogVisible = true
      this.$refs.roleEdit.form.roleId = row.id
    },
    saveDialogShow(type, row) {
      if (type === 2) {
        if (row.canDelete) {
          this.$refs.roleSave.dialogVisible = true
          for (const i in this.$refs.roleSave.form) {
            this.$refs.roleSave.form[i] = row[i]
          }
        }
      } else {
        this.$refs.roleSave.dialogVisible = true
      }
    },
    getRoleList() {
      this.roleListLoading = true
      GetRoleList()
        .then(res => {
          this.roleListLoading = false
          const resData = res.data
          if (resData.code === 0) {
            this.total = 0
            this.roleList = resData.data
            if (this.roleList.length > 0) {
              if (!this.roleActiveId) {
                this.roleActiveId = this.roleList[0].id
              }
              this.listQuery.page = 1
              this.getRoleUsersList()
            }
          }
        })
    },
    rowClick(row) {
      this.listQuery.page = 1
      this.roleActiveId = row.id
      this.getRoleUsersList()
    },
    getRoleUsersList() {
      this.userListLoading = true
      this.listQuery.id = this.roleActiveId
      ListRoleUser(this.listQuery)
        .then(res => {
          this.userListLoading = false
          const resData = res.data
          if (resData.code === 0) {
            this.userList = res.data.data.list
            this.userTotal = Number(res.data.data.total)
          }
        })
    },
  },
}
</script>
<style lang="scss">

</style>
