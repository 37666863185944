<template>
  <el-dialog
    :title="form.id ? '修改角色' : '新增角色'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item
        label="角色名称"
        prop="roleName"
        :rules="[ { required: true, message: '角色名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.roleName"
          v-input-filter
          maxlength="100"
        />
      </el-form-item>
      <el-form-item
        label="备注"
      >
        <el-input
          v-model="form.remark"
          v-input-filter
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { saveOrUpdateRole } from '@/api/system/role/role'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: '',
        roleName: '',
        remark: '',
      },
    }
  },
  methods: {
    dialogOpen() {
      if (this.form.id) {

      }
    },
    dialogClose() {
      this.form = {
        id: '',
        roleName: '',
        remark: '',
      }
      this.$refs.form.resetFields()
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          const submitData = {
            roleName: this.form.roleName,
            remark: this.form.remark,
          }
          if (this.form.id) {
            submitData.id = this.form.id
          }
          saveOrUpdateRole(submitData)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
    .assest-save-form {
        padding-right: 10px;
    }

    .assest-save-form .el-icon-question {
        position: absolute;
        top: 14px;
        right: -20px;
    }
</style>
